/* .superfund-legend {
    padding: 10px;
  }
   */
  /* Style for text next to circles */
  .superfund-legend-item {
    display: inline-block;
    vertical-align: middle; /* Aligns text vertically with circles */
    margin-right: 25px;

  }

  .attribute-text{
    text-align: right;
    margin-bottom: 0;
  }

  /* Style for color circles */
  .circle {
    vertical-align: middle;
    display: inline-block;
    width: 15px; /* Adjust circle size as needed */
    height: 15px; /* Adjust circle size as needed */
    border-radius: 50%; /* Ensures circles are round */
    margin-right: 5px; /* Adjust spacing between circle and text */
  }

  .proposed-site{
    background-color: #2a00ff;
  }

  .active-site{
    background-color: #ff57c7;
  }
  
  .deleted-site{
    background-color: #3ef519;
  }

  @media only screen and (max-width: 800px) {
    .superfund-legend{
        text-align: left;
        width: 50%;
      }
  }
