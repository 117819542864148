.footer {
    background: var(--darkTeal);
    background: linear-gradient(0, var(--secondaryMain) 0%, var(--main) 100%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer-questions {
    width: 100%;
    color: white;
    text-align: center;
    font-size: 20px;
    padding: 15px;
    line-height: 35px;
}

.footer-questions a {
    color: white !important;
    text-decoration: none;
}

.copyright-Text{
    margin: 0;
    color: white;
}

.footer-col-div {
    display: grid;
    min-height: 75px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.footer-link-li{
    width: 100%;
    margin: auto;
    text-align: left;
    display: flex;
    width: 50%;
}

.footer-link {
    font-weight: 400;
    color: white;
    font-size: 15px;
    text-decoration: none;
    /* width: 50%; */
    text-align: left;
    margin-bottom: 5px;
}

.footer-link:hover {
    color: var(--main);
    cursor: pointer;
}

.copyright-link{
    font-weight: 400;
    color: var(--main);
    font-size: 15px;
    text-decoration: none;
    /* width: 50%; */
    text-align: left;
    margin-bottom: 15px;
}

.copyright-link:hover {
    color: var(--accent);
    cursor: pointer;
}


@media only screen and (max-width: 1000px) {
    /* .footer-link-li{
        width: 35%;
    } */
}
