.purchase-confirmation-screen{
    height: 88vh;
}

.purchase-confirmation-div{
    min-height: 80%;
}

.purchase-confirmation-check{
    margin-top: 5%;
    margin-bottom: 15px;
    font-size: 75px;
    color: rgb(76, 190, 0);
}

.purchase-confirmation-header{
    font-size: 30px;
    margin-top: 35px;
    margin-bottom: 20px;
    font-weight: bold;
}

.purchase-confirmation-text{
    font-size: 20px;
    display: block;
    /* margin-top: 25px;
    margin-bottom: 25px; */
    width: 40%;
    margin:auto;
}

.purchase-confirmation-report-link{
    background-color: var(--main);
    color: white;
    font-size: 20px;
    width: 200px;
    display: block;
    padding: 5px;
    border-radius: 25px;
    margin: auto;
    text-decoration: none;
    margin-top: 50px;
    cursor: pointer;
}

.purchase-confirmation-report-link:hover{
    background-color: var(--accent);
    color: white;
}
