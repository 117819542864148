body {
  min-height: 90vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --main: #05112a;
  --mainTransparent: rgba(5, 17, 42, .8);
  --grayBackground: RGB(242, 242, 242);
  --secondaryMain: #09abdb;
  --accent: #09abdb;
  --white: white;
  --lightTeal:  #57899C;
  --darkTeal: #456C7B;
  --hover: #f4b14e;
  --orange: #ED661F;
  --orangeCTA: rgba(255, 93, 59, .8);
  /* --orangeCTA: #ff5d3b; */
  --yellow: yellow;
  --error: red; 
  --darkGrey: #e5e3e3;
  --lightGrey: #f5f4f4;
  --greyFont: #797878;
  --fullwidth: 100%;
  --borderRadius: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
