.policy-page{
    width: 80%;
    margin: auto;
}

.privacy-policy-title{
    margin: 50px;
}

.term-number{
    padding-left: 0;
}

.policy-item{
    display: block;
    width: 80%;
    text-align: left;
}

.policy-description{
    text-align: justify;
}

.policy-lists{
    margin: 0;
    text-align: left;
    display: list-item;
}

.terms-effective-date{
    margin: 25px 0 25px 0;
    text-align: center;
}