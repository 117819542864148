.mapbox-container {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  /* border-radius: 25px 25px 0 0; */
  flex-direction: column
}

.sidebarStyle {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}

.mapboxgl-canvas {
  height: 100%;
}

.mapboxgl-popup {
  font-family: 'Open Sans', sans-serif;
  max-width: 200px;
  min-width: 150px;
}

.mapboxgl-popup-content {
  font-family: 'Open Sans', sans-serif !important;
  border-radius: 15px !important;
  width: fit-content !important;
  min-width: 150px;
}

.main-popover-address {
  font-size: 16px !important;
  padding: 0;
  margin-bottom: 0;
}

.popover-header {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18px !important;
  margin-bottom: 20px !important;
}

.popover-result-item {
  text-align: left;
  padding: 0;
  display: block;
}

.popover-result-key {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  display: block;
  text-align: left;
  padding: 0;
  margin: 0;
}

.popover-result-value {
  width: 100%;
  display: block;
  text-align: left;
}

.epa-superfund-sites {
  /* border: 1px solid blue; */
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 13px solid #FF0000;
  display: inline-block
}

.epa-brownfield-sites {
  /* border: 1px solid blue; */
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 13px solid #DC143C;
  display: inline-block
}

.epa-toxics-release-inventory-sites {
  /* border: 1px solid blue; */
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 13px solid #B22222;
  display: inline-block
}

.nuclear-power-plants{
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 13px solid #CD5C5C;
  display: inline-block
}
.asbestos-mines-and-occurances{
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 13px solid #FF6347;
  display: inline-block
}
.wastewater-treatment-plants{
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 13px solid #FF4500;
  display: inline-block
}
.former-defense-sites{
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 13px solid #FFA500;
  display: inline-block
}

.cell-towers{
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 13px solid #FFD700;
  display: inline-block
}

.airports{
  width: 16px; /* Adjust width as needed */
  height: 18px; /* Adjust height as needed */
  background-color: #FFFF00; /* Change this to your desired color */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.incinerators{
  background-color: #F0E68C;
  /* border: 1px solid blue; */
  color: white;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 19px; */
  margin: 1px;
  border-radius: 50%;
}

.power-plants{
  background-color: #EEE8AA;
  /* border: 1px solid blue; */
  color: white;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 19px; */
  margin: 1px;
  border-radius: 50%;
}

.refineries{
  background-color: #FAFAD2;
  /* border: 1px solid blue; */
  color: white;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 19px; */
  margin: 1px;
  border-radius: 50%;
}

.natural-gas-wells{
  background-color: #FFFFE0;
  /* border: 1px solid blue; */
  color: white;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 19px; */
  margin: 1px;
  border-radius: 50%;
}

.oil-wells{
  background-color: #FFFACD;
  /* border: 1px solid blue; */
  color: white;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 19px; */
  margin: 1px;
  border-radius: 50%;
}

.pulp-and-paper-mills{
  background-color: #9ACD32;
  /* border: 1px solid blue; */
  color: white;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 19px; */
  margin: 1px;
  border-radius: 50%;
}

.landfills {
  background-color: #7FFF00;
  /* border: 1px solid blue; */
  color: white;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 19px; */
  margin: 1px;
  border-radius: 50%;
}

.hospitals{
  background-color: #1d434f  ; /* Change this to your desired color */
  width: 16px; 
  height: 18px; 
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.police-stations{
  width: 16px; /* Adjust width as needed */
  height: 18px; /* Adjust height as needed */
  background-color: #00FF00; /* Change this to your desired color */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.emergency-medical-service-stations{
  width: 16px; /* Adjust width as needed */
  height: 18px; /* Adjust height as needed */
  background-color: #ADFF2F; /* Change this to your desired color */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.fire-stations{
  width: 16px; /* Adjust width as needed */
  height: 18px; /* Adjust height as needed */
  background-color: #00FF7F; /* Change this to your desired color */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}