.remaining-page {
  display: grid;
}

.report-page {
  min-height: 86vh;
  /* background-color: RGB(242, 242, 242); */
}

.tab-panel{
  display: none;
}

.active{
  display: inline-block;
}

.report-summary {
  width: 70%;
  margin: auto;
  /* margin-top: 5%; */
  border-radius: 0 0 15px 15px;
  display: grid;
  background-color: #ffffff;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding-top: 10px;
  padding-bottom: 10px;
}

