
.loading-div {
    height: 70vh;
    margin-top: 15vh;
    font-size: 35px;
    margin-bottom: 50px;
    padding-bottom: 50px;
    display: block;
  }
  
  .custom-loader {
    margin: auto;
    margin-top: 15%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background:
      radial-gradient(farthest-side, var(--main) 94%, #0000) top/8px 8px no-repeat,
      conic-gradient(#0000 30%, var(--main));
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
    animation: s3 .7s infinite linear;
  }
  
  @keyframes s3 {
    100% {
      transform: rotate(1turn)
    }
  }
  
  