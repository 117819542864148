.pricing-cards {
    width: 70%;
    margin: auto;
    border-radius: var(--borderRadius);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    padding-top: 50px;
    overflow: auto;
    padding-bottom: 10px;
    background-color: transparent;
}

.pricing-card {
    padding: 10px;
}
/* 
@media screen and (max-width: 768px) {
    .pricing-card {
        flex: 1 0 100%; /* One card per row on small screens */
/* }  */
    /* } */

.card a {
    position: relative;
    z-index: 2;
    background: #fff;
    color: black;
    width: 150px;
    height: 40px;
    line-height: 40px;
    border-radius: var(--borderRadius);
    display: block;
    text-align: center;
    margin: 20px auto 0;
    font-size: 16px;
    cursor: pointer;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    text-decoration: none;

}

.cardButton{
    background: #fff;
    color: black;
    width: 150px;
    height: 40px;
    border: none;
    border-radius: var(--borderRadius);
    text-align: center;
    margin: 20px auto 0;
    font-size: 16px;
    cursor: pointer;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    text-decoration: none;
}
.cardButton:hover,
.card a:hover {
    background-color: var(--main);
    text-decoration: none;
    color: white !important;
}


@media only screen and (max-width: 800px) {
    .pricing-card{
        padding: 0;
        width: 90%;
        margin: auto;
    }
    .pricing-cards{
        width: 90%;
        display: block;
    }
    .card{
        width: 100%;
    }
}