.dashboard-screen {
  min-height: 90vh;
  width: 100%;
  background-color: RGB(242, 242, 242);
  justify-content: center;
  align-items: center;
}

.dashboard-container {
  width: 80%;
  margin: auto;
}

.dashboard-h1 {
  padding: 15px;
  font-size: 35px;
}

.purchased-reports-container{
  padding-top: 5%;
}

.purchased-reports-container-h2 {
  margin: auto;
  background-color: white;
  font-size: 20px;
  border-bottom: 1px solid var(--darkGrey);
  color: var(--greyFont);
  text-align: center;
  padding: 10px 5px;
  border-radius: var(--borderRadius);
}

.reports-user-email{
  /* float: right; */
  display: block;
  font-size: 14px;
  margin-bottom: 0;
  text-align: right;
  padding-right: 10px;
  /* display: inline-block; */
}

.account-container {
  margin-top: 5%;
  padding-bottom: 5%;
}

.account-container-h2{
  text-align: left;
  margin-left: 15px;
}

.subscription-container-grid{
  display: grid;
  margin: auto;
  grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
  gap: 25px;
}

.subscription-card{
  background-color: white;
  border-radius: var(--borderRadius);
}

.card-details{
  height: calc(100% - 55px);
  padding: 10px;
  margin-bottom: 5px;
}

.subscription-card-h3{
  font-size: 20px;
  border-bottom: 1px solid var(--darkGrey);
  color: var(--greyFont);
  padding: 10px;
}

.stripe-portal-form{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;  
}
/* 
.pricing-name{
  margin-top: 10px;
} */

.billing-value{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;  
  color: black;
  font-size: 30px;
  /* margin: 15px 0 25px 0; */
  text-align: center;
}

.billing-portal-button {
  /* display: block; */
  font-size: 16px;
  border-radius: var(--borderRadius);
  background: var(--main);
  color: white;
  border: none;
  font-weight: bold;
  padding: 5px 20px 5px 20px;
  width: 150px;
  /* margin: 0 10px 10px 10px; */
}

.billing-portal-button:hover {
  background: var(--accent);
  color: white;
}

.change-plan-button{
  font-size: 16px;
  text-decoration: none;
  background: transparent;
  text-align: center;
  color: var(--accent);
  border: 1px solid var(--accent);
  border-radius: var(--borderRadius);
  font-weight: bold;
  padding: 5px 10px 5px 10px;
  min-width: 85px;
  /* width: 50%; */
  margin: 10px;
  margin-top: -5px;
}

.change-plan-button:hover{
  color: var(--main);
  border: 1px solid var(--main);
  cursor: pointer;
}

.payment-information{
      /* display: block; */
    /* height: 100%; */
    padding: 10%;
    /* vertical-align: middle; */
    /* margin: 0;*/
}

/* Responsive Layou */
@media (max-width: 768px) {
  .dashboard-container{
    width: 95%;
  }

  .dashboard-container {
    width: 95%;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .billing-container,
  .plan-container {
    flex-basis: 100%;
    margin-bottom: 20px;
  }

  .billing-container:last-child,
  .plan-container:last-child {
    margin-bottom: 0;
  }
}