.blog-page {
  min-height: 80vh;
}

.breadcrumb-menu {
  width: 100%;
  padding-left: 25px;
  text-align: left;
  margin: auto;
  margin-top: 15px;
}

.blog-hero-section {
  display: block;
  margin: auto;
  /* margin-top: 5%; */
  width: 90%;
}

.blog-h1{
  color: var(--main);
  font-size: 40px;
  font-weight: 300;
  padding-top: 25px;
  padding-bottom: 0;
}

.blog-published-date{
  text-align: left;
  margin-top: 15px;
  padding-bottom: 0;
}

.blog-h2{
  font-size: 25px;
  margin-top: 40px;
  margin-bottom: 25px;
}

.blog-list{
  justify-content: left;
  display: list-item;
}

.ordered-blog-list{
  margin-top: 25px;
}

.blog-paragraph{
  text-align: left;
  font-size: 15px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.blog-picture{
  border-radius: 15px;
  width: 90%;
}

.breadcrumb-item {
  text-decoration: none;
  font-weight: 400;
  color: black;
  cursor: pointer;
}

.breadcrumb-item:hover{
  color: var(--accent);
}

.blog-categories {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 20px;
  margin: auto;
  /* margin-top: 25px; */
  cursor: pointer;
  background-color: var(--mainTransparent);
  padding: 10px 5px 10px 5px;
}

.blog-cta-div{
  display: flex;
  margin: auto;
  margin-top: 15px;
}

.blog-cta-button{
  background: var(--accent);
  border: 1px solid var(--mainTransparent);
  color: var(--white);
  font-size: 18px;
  /* border-radius: 25px; */
  font-weight: 400;
  width: fit-content;
}

.blog-cta-button:hover {
  background: transparent;
  border: 1px solid var(--accent);
  color: var(--accent);
}

/* .category-card:hover {
  background-color: var(--main);
} */

.category-card {
  /* border-radius: 10px 10px 0 0; */
  max-width: 150px;
  margin: auto;
  border-bottom: 2px solid transparent;
 /* background-color: rgba(9, 171, 219, 0.3); */
}


.category-card-title {
color: white;
  font-size: 17px;
  margin-bottom: 2px;
  margin-top: 2px;
  font-weight: 300;
  /* color: black; */
}

.category-card-title:hover {
  color: var(--accent);
}

.selected-category {
  /* background-color: var(--main) !important; */
  border-bottom: 2px solid var(--accent);
}

.selected-category-title {
  /* background-color: var(--main) !important; */
  color: var(--accent);
}

.category-grid {
  display: grid;
  width: 90%;
  margin: auto;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  margin-top: 3%;
  margin-bottom: 5%;
  gap: 20px;
}

.category-item {
  padding: 10px;
  cursor: pointer;
}

.blog-thumbnail{
  /* border-radius: 15px; */
  width: 100%;
}

.thumbnail-category{
  padding-top: 16px;
  font-size: 15px;
  color: var(--accent);
  text-align: left;
}

.thumbnail-title{
  /* padding-left: 10px; */
  /* padding-top: 10px; */
  font-size: 20px;
  font-weight: 300;
  text-align: left;
  font-weight: bold;
}

.blog-paragraph-item{
  padding-top: 25px;
  margin-bottom: 0;
}

.table-div{
  margin-bottom: 25px;
}

.no-posts-message{
  font-size: 25px;
  margin: auto;
  margin-top: 10%;
  width: 80%;
}

@media only screen and (max-width: 800px) {
  .blog-cta-button {
    display: inline;
    margin-top: 15px;
  }
}