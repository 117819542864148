.panel-div{
    width: 90%;
    margin: auto;
    /* display: flex;
    justify-content: center; 
    align-items: center;  */
}

.panel-menu{
    /* display: grid;
    grid-template-columns: 2fr 2fr;
    grid-gap: 300px;
    justify-items: center;
    align-items: center;  */
    display: inline-block;
    width: 100%;
    margin: auto;
  }

  .back-icon{
    float: left;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 25px;
    cursor: pointer;
    display: block;
  }
  
  .hide-back-icon{
    display: none;
  }
  
  .back-icon:hover{
    color: var(--accent);
  }

  .report-drop-down {
    border-radius: var(--borderRadius);
    padding: 3px;
    width: 200px;
    text-align: center;
    margin: 8px;
    float: left;
    color: var(--white);  
    border: 1px solid var(--accent);
    background-color: var(--accent);
    font-size: 17px;
    font-weight: 400;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: top -8px right -80px;
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='40' viewBox='0 0 20 20' width='200' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  }
  
  .report-drop-down:hover {
    color: var(--white);
    background-color: var(--main);
    border: 1px solid var(--main);
  }
  
  .report-drop-down:focus {
    outline: none;
  }
  
  .download-div{
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 235px;
    float: right;
    /* margin-right: 35px; */
  }
  
  .map-button-div{
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 235px;
    float: right;
  }
  
  
  .download-button{
    border: 1px solid var(--accent);
    cursor: pointer;
    height: 34px !important;
    color: var(--white);
    float: right;
    border-radius: var(--borderRadius);
    width: 200px !important;
    background-color: var(--accent);
  }

  .map-button{
    border: 1px solid var(--accent);
    cursor: pointer;
    height: 34px !important;
    color: var(--white);
    float: right;
    border-radius: var(--borderRadius);
    width: 200px !important;
    background-color: var(--accent);
  }

  .download-button:hover{
    color: var(--white);
    border: 1px solid var(--main);
    background-color: var(--main);
  }
  
  
  .map-button:hover{
    color: var(--white);
    border: 1px solid var(--main);
    background-color: var(--main);
  }
  
  .button-text{
    display: inline;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    padding-right: 25px;
  }
  
  .download-icon{
    padding: 2px;
  }
  
  .map-icon{
    padding: 2px;
    color: white;
    font-size: 25px;
  }

@media only screen and (max-width: 800px) {
    .panel-menu {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
    .download-div{
      margin-bottom: 15px;
      display: flex;
      width: 50px
    }   

    .download-button-div{
      width: 50px !important;
    }
    .map-button-div{
      width: 50px !important;
    }
    .map-button{
      width: 35px !important;
      border-radius: var(--borderRadius);
    }
    .download-button{
      width: 35px !important;
      border-radius: var(--borderRadius);
    }
    .download-icon{
      padding: 0;
    }
    .map-icon{
      padding: 0;
    }
    .panel-div{
      width: 100%;
    }
    .button-text {
      display: none;
    }
  
  }