.product-div{
    width: 80%;
    height: 85vh;
    margin: auto;
    /* margin-top: 5%; */
}

.product{
    display: grid;
    margin: auto;
    /* margin-top: 5%; */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    /* padding-bottom: 5%; */
    /* border: 1px solid #ccc; */
    border-radius: 25px;
}

.plan-title{
    margin-top: 5%;
    font-size: 35px;
}

.product li{
    list-style: square;
}

.product{
    border-radius: 10px;
    height: 100%;
}

.product-address{
    background-color: var(--main);
    border-radius: 25px;
}

.product-address-header{
    color: white;
    padding: 10px;
}

.plan-title{
    margin-top: -15px;
}

.purchase-price{
    font: 25px;
    font-weight: bold;
}

.product-address-line-header{
    margin: 0;
    font-size: 15px;
    color: var(--lightGrey);

}

.product-address-line-item{
    border-bottom: 1px solid white;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    color: white;
    font-size: 25px;
}

#checkout-button {
    width: 150px;
    border-radius: 25px;
    background: var(--accent);
    color: white;
    border: none;
    font-weight: bold;
    padding: 5px 20px 5px 20px;
    margin-top: 25px;
}

#checkout-button:hover{
    background: var(--main);
}

.checkout-disclaimer{
    text-align: justify;
    font-size: 13px;
    padding: 15px;
    width: 80%;
    margin: auto;
    margin-top: 25px;

}