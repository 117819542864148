.info-popover{
    font-family: 'Roboto', sans-serif;
      min-width: 50%;
      background-color: white;
      margin: auto;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.info-popover-header{
    font-size: 30px;
    width:auto;
    text-align: center;
    background-color: RGB(242, 242, 242);
    padding: 10px;
    border-radius: 5px;
}

.info-popover-text{
    font-size: 15px;
    padding: 15px;

}

