.navbar{
    padding-left: 2%;
    padding: 0 !important;
    background: var(--main);
    height: 100px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.container{
    background: var(--main)!important;
    height: 100px;
    max-width: 100% !important;
}

.navbar-brand{
    font-family: 'Josefin Sans', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    vertical-align: middle;
    padding-left: 15px !important;
    color: white !important;
    font-size: 25px !important;
    font-weight: bold;
    height: 100%;
}

.navbar-brand:before {
    content: 'EnviroHome';
    color: var(--white);
    font-size: 32px;
}

.navbar-brand:after {
    content: 'Pro';
    color: var(--accent);
    font-size: 32px;
}

#responsive-navbar-nav{
    width: 50% !important;
}

.navbar-nav{
    z-index: 1000;
    position: relative;
    background: var(--main) !important;
    justify-content: end;
    border-radius: 0 0 15px 15px !important;
    /* float: right !important; */
    text-align: center;
    width: 50%;
    padding-right: 25px;    
}

.navbar-toggler-icon {
    filter: invert(1);
  }

.header-logo{
    object-fit: cover;
    vertical-align: middle;
    /* margin-top: -5px; */
    /* margin-left: 150px !important; */
}

.navbar-toggler{
    outline: none !important;
    border: none !important;
    color: white;
}

.navbar-toggler:focus{
    box-shadow: none !important;
}


.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl{
    align-items: initial;
}

.nav-link{
    font-family: 'Roboto', sans-serif !important;
    color: white !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    /* font-weight: bold !important; */
    /* border-top: 1px #C0D2E5 solid !important; */
    padding: 10px 20px 10px 5px !important;
    white-space: nowrap;
    margin: auto;
    margin: 10px;
    background-color: transparent;
    border: none;
}

.nav-link:hover{
    color: var(--accent) !important;
    /* border-top: 1px var(--accent) solid !important; */
}

.nav-link.active{
    color: var(--accent) !important;
    border-top: 1px var(--accent) solid !important;
}
/* 
 .nav-dropdown{
    padding: 0 !important;
    background-color: var(--main) !important;
    color: white !important; 
} */


.dropdown-menu{
    padding: 0 !important;
    border-radius: 15px !important;
}

.dropdown-item{
    font-size: 15px !important;
    text-align: left !important;
    padding-bottom: 10px !important;
    background-color: var(--main) !important;
    color: white !important;
}

.dropdown-item:hover{
    color: var(--accent) !important;
}

@media only screen and (max-width: 1000px) {
    .container, .container-md, .container-sm {
        max-width: 100% !important;
        z-index: 1000;
    }
    .nav-link{
        width: 95%;
    }
    .nav-link.active{
        border-top: none !important;
    }
    .dropdown-menu{
        width: 70%;
        margin: auto !important;
    }
    .dropdown-item{
        text-align: right !important;
    }

}
