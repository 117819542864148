.pricing-table-screen{
    min-height: 80vh;
    width: 80%;
    margin: auto;
    position: relative;
}

.pricing-table-screen{
    margin-top: 3%;
}

.pricing-plan-h1{
    font-size: 35px;
}

.pricing-plan-h2{
    font-size: 20px;
    margin-bottom: 50px;
}

.pricing-table-div{
    display: block;
    border-radius: 25px;
    margin-bottom: 50px;
    /* margin-to: 10%; */
}

.PricingTable{
    border-radius: 10px;
    margin-bottom: 25px;
}

.per-report-plan{
    margin-top: 25px;
    margin-bottom: 50px;
}


.per-report-link{
    color: var(--main);
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
}

.per-report-link:hover{
    color: var(--orange);
}