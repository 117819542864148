.terms-page{
    width: 80%;
    margin: auto;
}

.terms-header{
    margin: 50px;
}

.term-number{
    padding-left: 0;
}

.terms-item{
    text-align: left;
}

.terms-description{
    text-align: justify;
}

.terms-address{
    text-align: left;
    margin-bottom: 0;
}

.terms-effective-date{
    margin: 25px 0 25px 0;
    text-align: center;
}