.error-screen{
    height: 85vh;
}

.error-h1{
    margin-top: 10%;
    font-size: 50px;
}

.error-h2{
    margin-top: 15px;
    font-size: 30px;
}

.error-message{
    width: 80%;
    margin: auto;
}

.error-message-details{
    padding-top: 15px;
    color: var(--error);
}