.table-container {
    margin-top: 25px;
    max-width: 100%;
    overflow-x: auto;
  }
  
  .table-wrapper {
    overflow-y: auto;
    max-height: 400px; /* Adjust as needed */
  }
  
  .responsive-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .responsive-table th, .responsive-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    max-width: 400px;
  }

  .data-link{
    color: var(--accent);
    text-decoration: none;
  }

  .data-link:hover{
    color: var(--main);
  }
  @media (max-width: 768px) {
    .responsive-table th, .responsive-table td {
      font-size: 14px;
    }
  }

  .data-table-header {
    background-color: #f2f2f2; /* Example background color for the header */
    position: sticky;
    top: 0; /* Stick the header to the top */
    z-index: 1; /* Ensure it's above other content */
    vertical-align: bottom;
}

