.map-page{
    min-height: 90vh;
    position: relative;
}

.map-div{
    position: relative !important;
    /* display: contents; */
    top: 0;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
    margin: auto;
    /* top: 0; */
    /* bottom: 0; */
    left: 0;
    right: 0;
}

.legend {
    padding-top: 5px;
    position: absolute;
    overflow: scroll;
    height: 100%;
    background-color: rgba(58, 57, 57, 0.9);
    padding-left: 15px;
    padding-bottom: 35px;
    right: 0;
    border-radius: 0 0 25px 25px;
    color: white;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 10px; /* Adjust the gap between items as needed */
    transition: all 0.3s ease;
    transform: translateX(0);
}

.toggle-sidebar{
    text-align: right;
    padding-right: 15px;
    cursor: pointer;
    color: white;
}

.left-align{
    text-align: left;
}

.toggle-sidebar:hover{
    color: var(--accent);
}

.legend.collapsed {
    width: 50px;
    /* transform: translateX(100%); */
}

.hidden{
    display: none !important;
}

.legend-header {
    margin: 15px 0 0 10px;
    font-family: 'Open Sans', sans-serif;
}

.setting-menu{
    height: fit-content;
    position: absolute;
    top: 0;
    width: 75px;
    border-radius: 0 0 25px 25px;
    background-color: rgba(58, 57, 57, 0.9);  
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.toggle {
    cursor: pointer;
    color: white;
    padding: 5px;
    display: inline-grid;
  }
  

  .toggle-switch {
    display: inline-block;
    background: #ccc;
    border-radius: 16px;
    width: 35px;
    height: 18px;
    position: relative;
    vertical-align: middle;
    transition: background 0.2s ease;
  }
  .toggle-switch:before, .toggle-switch:after {
    content: "";
  }
  .toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 14px;
    height: 14px;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: left 0.25s;
  }
  .toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  }
  .toggle-checkbox:checked + .toggle-switch {
    background: var(--accent);
  }
  .toggle-checkbox:checked + .toggle-switch:before {
    left: 19px;
  }
  
  .toggle-checkbox {
    position: absolute;
    visibility: hidden;
  }
  
  .toggle-label {
    margin-left: 5px;
    position: relative;
    top: 2px;
    color: white;
  }

  .current-color-mode{
    color: white;
    padding: 0;
    margin: 0;
  }

.legend div span {
    text-align: left;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    width: 10px;
    font-family: 'Open Sans', sans-serif;

}

.legend-item{
    font-size: 12px;
    text-align: left;
    display: flex;
    align-items: center; /* Vertical alignment */
    margin: 0;
  
}

.legend-icon{
    display: inline;
    margin: 0;
}

.legend-text{
    display: inline;
    margin-left: 10px;
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;
}

.roads{
    width: 100%;
    width: 18px;
    height: 5px;
    border-radius: 5px;
    background-color: #4354f0;
}

.transmission-lines{
    width: 100%;
    width: 18px;
    height: 5px;
    border-radius: 5px;
    background-color: #F33996;
}

.train-lines{
    width: 100%;
    width: 18px;
    height: 5px;
    border-radius: 5px;
    background-color: #40E0D0
}

