.under-construction-page {
  min-height: 75vh;
}

.under-construction {
  background-color: white;
  min-height: fit-content;
  border-radius: 10px;
  padding-top: 8%;
}

.under-construction-icon {
  font-size: 50px;
  margin: auto;
  color: var(--accent);
  margin-top: 20px;
}

.under-construction-message {
  padding: 20px;
  line-height: 2;
  font-size: 25px;
}

.under-construction-link{
  color: var(--accent);
  text-decoration: none;
}

.under-construction-link:hover{
  color: var(--main);
}
