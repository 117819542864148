.amplify-button{
    border-radius: var(--borderRadius) !important;
}

.amplify-checkbox__button{
    border: 1px solid #ccc;
    color: transparent;
}

.amplify-tabs-item{
    border-radius: var(--borderRadius) !important;
}
.amplify-field__show-password{
    
    border-radius: var(--borderRadius)!important;
}

.amplify-button--primary{
    background-color: var(--accent) !important;
}

.amplify-button--primary:hover{
    background-color: var(--main) !important;
}

.amplify-tabs-item[data-state=active]{
   color: var(--accent);
   border-color: var(--accent);

}

.amplify-icon{
    border-radius: var(--borderRadius) !important;
}

/*
.amplify-field__show-password:hover {
    border-radius: 0 25px 25px 0;
} */

.amplify-checkboxfield{
    align-items: center;
}

.amplify-checkboxfield:focus-visible{
    border: none;
}

.amplify-checkbox__button::before{
    border-style: none !important;
}

.amplify-checkbox__button--error::before{
    border: 1px solid #ccc;
    border-radius: var(--borderRadius) !important;
}

/* 
.amplify-checkbox__button--error{
    --amplify-internal-checkbox_button-focused-before-border-color: var(--main) !important;
} */
.amplify-checkbox__icon--checked{
    background-color: var(--accent) !important;
}

.password-requirements{
    font-size: 13px;
    text-align: left;
    padding: 0;
    margin:0;
    margin-left: 15px;
}

.password-requirement{
    font-size: 13px;
    text-align: left;
    padding: 0;
    margin: 0;
    line-height: .5;
    margin-left: 15px;
}