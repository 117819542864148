.search-div {
    min-height: 85vh;
    position: relative;
}

.search-header {
    text-align: center;
    padding: 5% 0 2% 0;
    font-weight: 500;
    font-weight: bold;
    font-size: 30px;
}

.search-widget {
    display: grid;
    padding: 0 0 8vh 0;
    margin: auto;
    /* margin-left: 30px; */
}

@media only screen and (max-width: 800px) {
    .search-header {
        padding: 15% 25px 0 25px;
    }
    
    .search-widget {
        /* width: 80%; */
        padding-top: 4vh;
        padding-bottom: 14vh;
    }
}