/* .superfund-legend {
    padding: 10px;
  }
   */
  /* Style for text next to circles */
  .police-stations-legend-item {
    display: inline-block;
    vertical-align: middle; /* Aligns text vertically with circles */
    margin-right: 25px;

  }

  .attribute-text{
    text-align: right;
    margin-bottom: 0;
  }

  /* Style for color circles */
  .circle {
    vertical-align: middle;
    display: inline-block;
    width: 15px; /* Adjust circle size as needed */
    height: 15px; /* Adjust circle size as needed */
    border-radius: 50%; /* Ensures circles are round */
    margin-right: 5px; /* Adjust spacing between circle and text */
  }

  .local-site{
    background-color: #ed0707;
  }

  .special-jurisdiction-site{
    background-color: #37c7fb;
  }
  
  .sheriffs-office{
    background-color: #18fe0b;
  }
  .primary-state-agency{
    background-color: #8104f6;
  }

  .constable-marshall{
    background-color: #1262f8;
  }
  @media only screen and (max-width: 800px) {
    .police-stations-legend{
        text-align: left;
        width: 50%;
      }
  }
