
.envirohomepro-section{
    background-color: #f6f6f6;
    margin-top: 5%;
    text-align: center;
    display: inline-block;
}
.envirohomepro-h2{
    width: 80%;
    margin: auto;
    margin-top: 50px;
    font-size: 25px;
    margin-bottom: 30px;
    padding-top: 25px;
    font-weight: 400;

}

.envirohomepro-description{
    width: 90%;
    margin: auto;
    margin-bottom: 50px;
}

.envirohomepro-link{
    margin-top: 25px;
    display: inline-block;
    margin-bottom: 25px;
    background-color: var(--main);
    color: white;
    width: 200px;
    height: 35px;
    text-decoration: none;
    /* padding: 3px; */
    border-radius: var(--borderRadius);
    font-size: 25px;

}

.envirohomepro-link:hover{
    background-color: var(--accent);
    color: white;
}



.envirohomepro-logo:before {
    font-family: 'Josefin Sans', sans-serif;
    content: 'EnviroHome';
    color: var(--main);
    font-size: 2rem;
}

.envirohomepro-logo:after {
    font-family: 'Josefin Sans', sans-serif;
    content: 'Pro';
    color: var(--accent);
    font-size: 2rem;
}

.sampleEnviroHomeImages{
    width: 80%;
}

.slide-show{
    text-align: center;
}

.slide-icon{
    font-size: 25px;
}

.slide-button{
    height: 50px;
    border: none;
    background: none;
}

#left-slide-button{
    border-right: none;
    border-radius: 5px 0 0 5px;
}

#right-slide-button{
    border-left: none;
    border-radius: 0 5px 5px 0;

}

@media only screen and (max-width: 800px) {
    .slide-button{
        height: 50px;
        width: 30px;
        border: none;
        background-color: transparent;
}
}