.float-div-container {
  width: 100%;
  padding-top: 5%;
  margin: auto;
}

.float-left-div {
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  float: left;
}

.float-right-div {
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  float: right;
}


.report-summary-address-header {
  font-weight: 300;
  margin: auto;
  margin-top: 1%;
  margin-bottom: 5px;
  font-size: 35px;
  /* background-color: white; */
  width: 90%;
  border-radius: 5px 5px 5px 5px;
  padding-top: 20px;
  padding-bottom: 10px;
  /* border: 1px solid #ccc; */
}

.report-summary-sub-header {
  font-size: 25px;
  margin-bottom: 5px;

}

.cateogory-score{
  display: inline-block;
  border-radius: 25px;
  font-size: 25px;
  font-weight: 300;
}

.cateogory-score-background{
  position: relative;
  font-family: 'Josefin Sans', sans-serif;
  display: inline-block;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 44px;
  border-radius: 50px;
  font-size: 22px;
}

.cateogory-score-background span {
  /* font-size: 20px;
  position: absolute;
  bottom: 0;  */
  /* left: 55x; */
  /* transform: translateX(-10%); */
}


@media only screen and (max-width: 800px) {
  .float-div-container {
    padding-top: 25px;
  }

  .report-summary-address-header {
    width: 90%;
    margin-bottom: 25px;
  }

  .report-summary-address-sub-header {
    width: 90%;
    padding: 15px;

  }

  .report-summary-over {
    width: 90%;
  }

  .report-summary {
    width: 90%;
  }

  .report-summary-legend {
    width: 90%;
  }

  .category-score {
    padding-top: 15px;
  }
}