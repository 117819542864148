.address-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 28rem;
    margin: auto;
}

.form-field-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;
    border-radius: var(--borderRadius);
    border: 1px solid #ccc;
}

.missing-value{
    border: 1px solid var(--error);

}

.form-field {
    width: 90%;
    border: none;
    margin-left: 15px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 14px;
}

.input-label{
    text-align: left;
    margin: auto;
    font-size: 12px;
    width: 100%;
    padding-top: 5px;
    padding-left: 16px;
}

.form-field:focus-visible {
    outline: none;
}

.short-form-field-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    border: none;
    column-gap: 20px;
}

.short-form-field {
    margin-bottom: .25rem;
    margin-left: 15px;
    width: 80%;
    border-radius: var(--borderRadius);
    font-size: 14px;
    /* padding: 10px; */
    border: none;
}

.short-form-field:focus-visible{
    outline: none;
}

.button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.search-button {
    width: 200px;
    height: 40px;
    color: white;
    font-size: 15px;
    background-color: var(--main);
    border-radius: var(--borderRadius);
    border: none;
    /* background-color: #103F54; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    text-align: center;
    margin: auto;
    margin-top: 20px;
}

.search-button:hover {
    color: white;
    background-color: var(--accent) ;
    border: none;
}

.validation-error{
    text-align: left;
    color: var(--error);
}

.auto-complete-input:focus {
  outline: none;
}

.search-button-div {
    width: 100%;
    margin-top: 5%;
    position: relative;
    text-align: center;
}


button {
    cursor: pointer;
}

@media only screen and (max-width: 800px) {
    .search-button-div {
        margin-top: 5%;
    }

    #search-form {
        width: 100%;
    }

    .search-widget {
        width: 80%;
        justify-content: center;
        padding-top: 4vh;
        padding-bottom: 14vh;
    }

}