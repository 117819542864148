ul li {
  flex: 1;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-menu {
  width: 100%;
  padding: 0;
  display: inline-block;
  margin: auto;
  background-color: var(--darkGrey);
  border-radius: var(--borderRadius);
  justify-content: space-between;
}

.tab-panel {
  background-color: white;
  border-radius: var(--borderRadius);
  width: 90%;
  margin: auto;
}

.tab-menu-item {
  display: inline-flex;
  height: 2em;
  margin-bottom: 0;
  width: 180px;
  text-overflow: clip;
  padding: 20px 0 20px 0;
  line-height: 50px;
  cursor: pointer;
  color: black;
  vertical-align: bottom;
}

.tab-menu-item:focus {
  color: var(--main);
}

.tab-menu-item:focus {
  color: black;
}

.tab-menu-item:focus-visible {
  outline: none;
}

.tab-menu-item:hover {
  color: var(--main);
  /* border-bottom: 1px solid #57899C; */
  background-color: var(--lightGrey);
  border-radius: var(--borderRadius);
}

/* .report-overall-score {
  padding-top: 30px;
  font-size: 30px;
  font-weight: 300;
} */

.tab-menu-tem:focus-visible {
  color: var(--main);
}

.react-tabs__tab--selected {
  /* background-color: transparent; */
  color: var(--main);
  border-bottom: 1px solid var(--main);
  font-weight: 400;
  border-right: none;
  /* background-color: white;
  border-radius: 10px 10px 0 0; */
}

.react-tabs__tab--selected:active {
  color: var(--main);
}

.react-tabs__tab--selected:focus {
  color: var(--main);
}

.last-tab-div {
  padding-bottom: 50px;
}

.report-table{
    width: 90%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 5%;
}

.report-table-h{
  background-color: var(--accent);
  border-radius: var(--borderRadius);
  color: var(--white);
}

.report-table-th{
  text-align: left;
  padding: 5px;
}

.overlay-button-trigger{
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  display: inline-block;
  vertical-align: text-bottom !important;
  padding: 0 0 0 5px !important;
}

.overlay-button-trigger:hover{
  color: var(--main) !important;
}

.overlay-button-icon{
  font-size: 20px;
}

.weight-header{
  display: inline-block;
  padding: 0;
  margin: 0;
}

.report-table-data-score{
  text-align: center;
}

.report-table-row{
  height: 40px;
  border-bottom: 1px solid #ccc;
  text-align: left;
}

.report-table-td{
  padding: 10px;
  /* width: 37.5%; */
}

.report-table-data{
  padding-left: 5px;
}

#report-table-data-category{
  width: 67%;
}

.report-table-data-weight{
  width: 13%
}

.weight-header-popover{
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  font-size: 20px;
}

.weight-description-popover{
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  font-size: 15px;
  /* margin-left: 25px;
  margin-right: 25px; */
  padding: 15px;
}
.report-table-data-score{
  /* width: 20%; */
  padding: 5px;
}

.score-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  color: var(--black);
  font-family: Josefin Sans, sans-serif;
  height: 40px;
  text-align: center;
  width: 40px;
}

.offset{
  padding-left: 5px;
}

.expand-icon{
  font-size: 20px;
  padding-left: 10px;
  width: 5%;
  min-width: 50px;
  cursor: pointer;
  color: var(--main);
  line-height: 1;
}

.expand-icon:hover{
  color: var(--accent);
  font-size: 20px;
}


.expanded-details-tr{
  border-bottom: 1px solid #ccc;
  /* background-color: #ccc; */
}

.expanded-details{
  padding: 10px;
}

.expanded-details-grid {
  padding: 15px 0 15px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr)); /* Adjust the column width as needed */
  gap: 20px; /* Adjust the gap between cards */
  max-height: 60vh;
  overflow: auto;
}

.single-card-grid {
  grid-template-columns: 1fr; /* Make a single card take full width */
}

.no-card-grid{
  display: none;
}
.expanded-details-record{
  /* height: fit-content; */
  padding: 15px;
  background-color: #f0f0f0;
  /* background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9 74%); */
  border-radius: var(--borderRadius);
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
}

/* Remove border-bottom for the last record */
.expanded-details-record:last-child {
  border-bottom: none;
}

.expanded-details-description{
  font-size: 20px;
  /* margin-left: 40px; */
  margin-top: 15px;
}

.expanded-details-text-key{
  font-family: 'Josefin Sans', sans-serif;
  font-size: 17px;

}
.expanded-details-text{
  text-align: left;
  margin: auto;
  padding-left: 10px;
  font-size: 15px;
  /* margin-left: 5%;
  margin-right: 5%; */
    /* padding-left: 75px; */
}

.topic-explantion{
  background-color: var(--grayBackground);
  border-radius: var(--borderRadius);
  padding: 15px;
  margin-top: 25px 
}

.no-results-text {
  text-align: center;
  margin-bottom: 10px;
}



@media only screen and (max-width: 800px) {
  .tab-panel{
    width: 95%;
  }
  
  .report-table{
    width: 100%;
  }

  .tab-menu-item {
    width: fit-content;
  }
  .expanded-details-grid{
    display: block;
  }
  .expanded-details-record {
    margin-bottom: 15px;
  }
  .report-table-data-weight{
    width: 25%
  }
  
}

@media (max-width: 1200px) {

  .tab-menu-item {
    width: calc(100% / 10);
    font-size: 0;
  }

  .tab-menu-item>* {
    font-size: 14px;
  }

  svg {
    font-size: 20px;
  }
}
