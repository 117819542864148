.loading-error{
    background-color: white;
    min-height: fit-content;
    border-radius: 10px;
  }
  
  .loading-error-icon{
    font-size: 30px;
    margin: auto;  
    color: var(--error);
    margin-top: 20px;
  }
  
  .loading-error-message{
    padding-bottom: 25px;
  }