/* .css-1omuo8w-MuiToolbar-root {
    border-radius: 10px;
} */

/* .MuiToolbar-gutters{
    border-radius: 10px;
} */

/* .css-ew7e2j-MuiPaper-root {
    border-radius: 10px;
} */

.downloadIcon{
    color: var(--main);
}

.view-icon{
    font-size: 25px !important;
}

.css-ruvc88-MuiTableRow-root:hover{
    color: black;
}